import React from "react"
import { Helmet } from "react-helmet-async"
import Layout from "../components/layout"
import EmailSignUp from "../components/EmailSignUp"

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>About</title>
      </Helmet>
      <h1>About</h1>
      <p>This site is a free online hymn supplement for Christians who sing a cappella, shape-note music.</p>
      <p>Your main hymnal is probably something like <em><a href="https://truthbooks.com/phass-hymnal">Psalms, Hymns, and Spiritual Songs</a></em>, <em><a href="https://www.taylorpublications.com/index.php?route=product/product&product_id=6474">Songs of Faith and Praise</a></em>, <em><a href="https://www.taylorpublications.com/index.php?route=product/category&path=80">Praise for the Lord</a></em>, <em><a href="https://www.rjstevensmusic.com/product/hymns-for-worship-revised/">Hymns for Worship</a></em>, or even <em><a href="http://www.paperlesshymnal.com">The Paperless Hymnal</a></em>.</p>

      <p>The collection of hymns on this website is small in comparison to those hymnals. But there are some special things here which you may find useful:</p>

      <ul>
        <li><b>Great for handouts.</b> You can easily, freely, and legally print PDF handouts of any of the hymns on this site. This can be handy for a singing in someone’s home, or for special events like a wedding.</li>
        <li><b>PowerPoint: Standard and Widescreen.</b> Both standard <i>and widescreen</i> PowerPoint slides are available for each hymn.</li>
        <li><b>Extra verses, alternate music.</b> For hymns already in your hymnal, the version here may contain additional verses, alternate music, or slight variations in wording which you may appreciate.</li>
      </ul>

      <h2 id="Kinds-of-Hymns">Kinds of Hymns</h2>
      <p>There are a few different kinds of hymns you’ll find here:</p>
      <ol>
        <li><strong>New Hymns.</strong> Completely new hymns which don’t yet appear in any hymnals.</li>
        <li><strong>Old Hymns with New Music.</strong> Older hymns whose thoughts are well-worth singing but updated with completely new music.</li>
        <li><strong>Classic Hymns.</strong> Carefully-selected, well-known hymns that have stood the test of time. Your hymnal will likely have many of these, but the versions here may offer more verses, slightly different wording, or slightly different music than what appears in your hymnal. You’ll want to carefully review such hymns before using them with your congregation, especially if the hymn is different than the version already in your hymnal. But take a look: you might be pleasantly surprised what you find!</li>
      </ol>
      <h2 id="Available-Formats">Available Formats</h2>
      <p>Each hymn is available to download in three different formats:</p>
      <ol>
        <li><strong>PDF for Print.</strong> Our PDFs are designed to be easily readable and sized to fit within a standard hymnal (we use a page size of 5.75” x 8.25”). When printing on a standard US Letter piece of paper (8.5” x 11”), If you set the scale to 100%, you’ll get a print that can be cropped and inserted inside of a typical hymnal. If you scale the contents to fit the entire page, you’ll get an extremely-readable, large print handout. This can be useful for get togethers or special events (like a wedding or funeral).</li>
        <li><strong>Standard PowerPoint (4:3)</strong> Our <em>Standard PowerPoint</em> layout uses the traditional, non-widescreen PowerPoint size (a 4:3 aspect ratio). The words and music are designed to be as large and legible as possible.</li>
        <li><strong>Widescreen PowerPoint (16:9).</strong> In addition to the <em>Standard PowerPoint</em> layout, we also offer each hymn in a <em>Widescreen PowerPoint</em> layout (using a 16:9 aspect ratio). For most hymns, the widescreen layout has twice the amount of content per slide, significantly reducing the number of slide transitions. The words and music are still made as large and legible as possible, but everything is a little bit smaller when compared to the <em>Standard PowerPoint (4:3)</em> layout. If your church uses a widescreen format, give this layout a try. If you find the words or music are too small or hard to read, you can always use the <em>Standard PowerPoint (4:3)</em> layout instead.</li>
      </ol>
      <h2>Who Is Behind This?</h2>
      <p>This site is maintained by Adam Cooper. You can reach him at <a href="mailto:adam@hymnsonline.org">adam@hymnsonline.org</a>.</p>
      <EmailSignUp />
    </Layout>
  )
}

